
.edit-view-mode{

	label {
		width:50px;
		height:20px;
		position: relative;
		display: block;
		background: #ebebeb;
		border-radius: 20px;
		box-shadow: inset 0px 5px 15px rgba(0,0,0,0.4), inset 0px -5px 15px rgba(255,255,255,0.4);
		cursor: pointer;
		transition: 0.3s;
	}
	 label:after {
		content: "";
		width:18px;
		height: 18px;
		position: absolute;
		top:1px;
		left:1px;
		background: linear-gradient(180deg,#313131,#313131);
		border-radius: 18px;
		box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
		transition: 0.3s;
	}
	 input {
		width: 0;
		height: 0;
		visibility: hidden;
	}
	 input:checked +  label {
		background: #242424;
	}
	 input:checked +  label:after {
		left:49px;
		transform: translateX(-100%);
		background: linear-gradient(180deg,#1AA5C0,#127588);
	}
	 label:active:after{
		width: 26px;
	}
	
	 input:checked +  label {
		background: #ebebeb;
	}
	 label svg {
		position: absolute;
		width: 12px;
		top:4px;
		z-index: 100;
	}
	 label svg.view {
		left:4px;
		fill:#fff;
		transition: 0.3s;
	}
	 label svg.edit {
		left:34px;
		fill:#000000;
		transition: 0.3s;
	}
	 input:checked +  label svg.view {
		fill:#000000;
	}
	 input:checked +  label svg.edit {
		fill:#fff;
	}
}
 