/* src/styles/tailwind.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  --toastify-z-index: 99999999999999 !important;
}

body,
html {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.no-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #17a5d2;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #17a5d2;
}

input[type="range"] {
  height: 39px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #ffffff;
  background: #e8e8e8;
  border-radius: 7px;
  border: 0px solid #ffffff;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000031;
  border: 3px solid #335d84;
  height: 29px;
  width: 28px;
  border-radius: 45px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #e8e8e8;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #ffffff;
  background: #e8e8e8;
  border-radius: 7px;
  border: 0px solid #ffffff;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000031;
  border: 3px solid #335d84;
  height: 29px;
  width: 28px;
  border-radius: 45px;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #e8e8e8;
  border: 0px solid #ffffff;
  border-radius: 14px;
  box-shadow: 1px 1px 1px #ffffff;
}

input[type="range"]::-ms-fill-upper {
  background: #e8e8e8;
  border: 0px solid #ffffff;
  border-radius: 14px;
  box-shadow: 1px 1px 1px #ffffff;
}

input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000031;
  border: 3px solid #335d84;
  height: 29px;
  width: 28px;
  border-radius: 45px;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #e8e8e8;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #e8e8e8;
}

::placeholder {
  color: #c2d3de;
  opacity: 1;
  /* Firefox */
  font-size: 16px;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #c2d3de;
  font-size: 16px;
}

.checkbox_control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 57px;
  margin-bottom: 3px;
  padding-top: 6px;
  cursor: pointer;
  font-size: 12px;
}

.checkbox_control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox_control_indicator {
  position: absolute;
  top: 5px;
  left: 14px;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: 0px;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  margin-right: 5px;
}

.checkbox_control:hover input ~ .checkbox_control_indicator,
.checkbox_control input:focus ~ .checkbox_control_indicator {
  background: #cccccc;
}

.checkbox_control input:checked ~ .checkbox_control_indicator {
  background: #2aa1c0;
}

.checkbox_control:hover
  input:not([disabled]):checked
  ~ .checkbox_control_indicator,
.checkbox_control input:checked:focus ~ .checkbox_control_indicator {
  background: #0e6647d;
}

.checkbox_control input:disabled ~ .checkbox_control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.checkbox_control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.checkbox_control input:checked ~ .checkbox_control_indicator:after {
  display: block;
}

.checkbox_control-checkbox .checkbox_control_indicator:after {
  left: 11px;
  top: 8px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox_control-checkbox input:disabled ~ .checkbox_control_indicator:after {
  border-color: #7b7b7b;
}

.checkbox_control-checkbox .checkbox_control_indicator::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #2aa1c0;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes s-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.checkbox_control-checkbox input + .checkbox_control_indicator::before {
  animation: s-ripple 250ms ease-out;
}

.checkbox_control-checkbox input:checked + .checkbox_control_indicator::before {
  animation-name: s-ripple-dup;
}

.masked-overflow {
  scrollbar-width: thin;
  scrollbar-color: #1aa5c1 transparent;
}

.masked-overflow {
  --scrollbar-width: 8px;
  --mask-height: 32px;
  overflow-y: auto;

  padding-bottom: var(--mask-height);

  --mask-image-content: linear-gradient(
    to bottom,
    transparent,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent,
    transparent
  );
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position:
    0 0,
    100% 0;
  mask-repeat: no-repeat, no-repeat;
}

.startRecording {
  background: linear-gradient(179.64deg, #299bdb 38.53%, #6cd9d6 103.04%);
}

.stopRecording {
  background: linear-gradient(179.64deg, #db2929 38.53%, #d97c6c 103.04%);
}

.startRecording:hover {
  background: linear-gradient(12deg, #299bdb 38.53%, #6cd9d6 103.04%);
  box-shadow: -1px 1px 7px 0px #369ff461;
}

.stopRecording:hover {
  background: linear-gradient(12deg, #db2929 38.53%, #d97c6c 103.04%);
  box-shadow: -1px 1px 7px 0px #f4433661;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: linear-gradient(179.64deg, #db2929 38.53%, #d97c6c 103.04%);
  box-shadow: 0 0 0 0 #db2929;
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #db292970;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #db292900;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #db292900;
  }
}

input:focus-visible,
select:focus-visible,
button:focus-visible {
  outline-offset: 0px;
  outline-color: #299bdb;
}

.hoverItems {
  background: linear-gradient(180deg, #2799da 0%, #6cd9d5 100%);
  box-shadow: 0px 1.67px 3.34px 0px #00000040;

  color: #ffffff;
  cursor: pointer;
}

.hoverItemBtn:hover {
  background: linear-gradient(180deg, #2799da 0%, #6cd9d5 100%);
  box-shadow: 0px 1.67px 3.34px 0px #00000040;

  color: #ffffff;
  cursor: pointer;
}

.hoverItemBtn:hover path {
  stroke: white;
}

.ql-toolbar {
  border-radius: 5px;
  border: 1px solid #cccccc7a;
}

.ql-container {
  border-radius: 5px;
  margin-top: 12px !important;
  min-height: 200px;
  border-top: 1px !important;
  border: 1px solid #cccccc7a;
}

.ql-toolbar.ql-snow {
  position: sticky !important;
  top: 0 !important;
  background: #ffffff !important;
  z-index: 9;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #20a8d3 !important;
  fill: #20a8d3 !important;
}

.ql-editor {
  min-height: 200px;
}

body {
  overflow: hidden;
}

.Toastify__toast-container {
  z-index: 99999999999999 !important;
}

.searchBtn {
  right: 0;
  width: 41px;
  height: 100%;
  background-color: #eee;
}

[id^="react-select-"] {
  padding-left: 4px;
  padding-right: 4px;
}

/* [1] The container */
.img-hover-zoom {
  overflow: hidden;
  /* [1.2] Hide the overflowing of child elements */
  position: relative;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition:
    transform 1s,
    filter 2s ease-in-out;
  filter: blur(0);
  transform: scale(1);
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  filter: blur(2px);
  transform: scale(2);
}

.img-hover-zoom:hover span {
  display: block;
  position: absolute;
  top: Calc(50% - 19px);
  left: Calc(50% - 19px);
  z-index: 1;
}

.img-hover-zoom span {
  display: none;
}

.mainBoxShadow {
  box-shadow: 0px 1.86px 3.72px 0px #00000040;
}

.modalBoxShadow {
  box-shadow: 0px -6px 18px 0px #eee;
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: white;
}

.react-transform-wrapper {
  height: 100% !important;
  width: 100% !important;
}

.react-transform-component {
  height: 100% !important;
  width: 100% !important;
}

.react-transform-component:active {
  cursor: move;
}

.noScale {
  --scale-factor: white !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.pdfContent {
  margin-bottom: 40px;
}

.cornerstone-canvas,
.cornerstone-canvas-wrapper {
  border-radius: 0.375rem;
  z-index: 9;
}

.cornerstone-canvas-wrapper {
  g polyline {
    fill: transparent !important;
    stroke-width: 4px !important;
    stroke: rgb(0, 255, 0) !important;
  }

  .annotation-polygon {
    fill: rgba(0, 255, 0, 0.06) !important;
    stroke-width: 2px !important;
    stroke: rgb(0, 255, 0) !important;
    stroke: rgb(0, 255, 0) !important;
  }

  g circle {
    fill: rgba(0, 255, 0, 0.06) !important;
    stroke-width: 2px !important;
    stroke: rgb(0, 255, 0) !important;
  }

  .annotation-point path {
    fill: rgb(0, 255, 0) !important;
    stroke-width: 4px !important;
    stroke: transparent !important;
  }

  text {
    fill: rgb(255, 255, 255);
    paint-order: stroke;
    stroke: rgb(0, 0, 0);
    stroke-width: 1px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 600;
    text-anchor: middle;
    visibility: visible;
  }
}

button:disabled img {
  filter: saturate(0);
}

.DicomData {
  color: rgb(0, 255, 0);
  font-size: 10px;
  z-index: 10;

  user-select: none;
  pointer-events: none;
}

.noSelect {
  user-select: none;
  pointer-events: none;
}

/* INPUT RANGE */
input[type="range"] {
  align-self: center;
  border: solid 1.5em transparent;
  padding: 0;
  /* width: 13em;*/
  height: 2.25em;
  background:
    repeating-linear-gradient(
        90deg,
        #bfbfbf,
        #bfbfbf 0.0625em,
        transparent 0.0625em,
        transparent 2em
      )
      no-repeat 50% 0.4375em border-box,
    repeating-linear-gradient(
        90deg,
        #bfbfbf,
        #bfbfbf 0.0625em,
        transparent 0.0625em,
        transparent 2em
      )
      no-repeat 50% 4.1875em border-box,
    linear-gradient(
        90deg,
        rgba(189, 189, 189, 0),
        #bdbdbd,
        rgba(189, 189, 189, 0)
      )
      no-repeat 50% 50% border-box;
  background-size:
    12.0625em 0.625em,
    12.0625em 0.625em,
    100% 2.25em;
  font-size: 1em;
  cursor: pointer;
}

input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  position: relative;
  width: 13em;
  height: 0.5em;
  border-radius: 0.1875em;
  background: rgba(196, 196, 196, 0.825);
}

input[type="range"]::-moz-range-track {
  width: 13em;
  height: 0.5em;
  border-radius: 0.1875em;
  background: rgba(196, 196, 196, 0.825);
}

input[type="range"]::-ms-track {
  border: none;
  width: 13em;
  height: 0.5em;
  border-radius: 0.1875em;
  background: rgba(196, 196, 196, 0.825);
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  display: none;
}

input[type="range"]::-webkit-slider-thumb {
  border: none;
  width: 4em;
  height: 2em;
  border-radius: 0.5em;
  box-shadow:
    -0.125em 0 0.25em #908f8f,
    inset -1px 0 1px #fff;
  background:
    radial-gradient(
        #ebe1e0 10%,
        rgba(236, 235, 235, 0.2) 10%,
        rgba(236, 235, 235, 0) 72%
      )
      no-repeat 50% 50%,
    radial-gradient(at 100% 50%, #e7e6e6, #e9e8e8 71%, rgba(0, 0, 0, 0) 71%)
      no-repeat 2.5em 50%,
    linear-gradient(90deg, #e9e9e9, #cbcbcb) no-repeat 100% 50%,
    radial-gradient(at 0 50%, #d8d8d8, #c3c3c3 71%, rgba(0, 0, 0, 0) 71%)
      no-repeat 0.75em 50%,
    linear-gradient(90deg, #e2e1e1, #cfcfcf) no-repeat 0 50%,
    linear-gradient(#cbcaca, #fafafa, #f8f7f7, #cccccc);
  background-size: 0.825em 100%;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  width: 4em;
  height: 2em;
  border-radius: 0.5em;
  box-shadow:
    -0.125em 0 0.25em #908f8f,
    inset -1px 0 1px #fff;
  background:
    radial-gradient(
        #ebe1e0 10%,
        rgba(236, 235, 235, 0.2) 10%,
        rgba(236, 235, 235, 0) 72%
      )
      no-repeat 50% 50%,
    radial-gradient(at 100% 50%, #e7e6e6, #e9e8e8 71%, rgba(0, 0, 0, 0) 71%)
      no-repeat 2.5em 50%,
    linear-gradient(90deg, #e9e9e9, #cbcbcb) no-repeat 100% 50%,
    radial-gradient(at 0 50%, #d8d8d8, #c3c3c3 71%, rgba(0, 0, 0, 0) 71%)
      no-repeat 0.75em 50%,
    linear-gradient(90deg, #e2e1e1, #cfcfcf) no-repeat 0 50%,
    linear-gradient(#cbcaca, #fafafa, #f8f7f7, #cccccc);
  background-size: 0.825em 100%;
}

input[type="range"]::-ms-thumb {
  border: none;
  width: 4em;
  height: 2em;
  border-radius: 0.5em;
  box-shadow:
    -0.125em 0 0.25em #908f8f,
    inset -1px 0 1px #fff;
  background:
    radial-gradient(
        #ebe1e0 10%,
        rgba(236, 235, 235, 0.2) 10%,
        rgba(236, 235, 235, 0) 72%
      )
      no-repeat 50% 50%,
    radial-gradient(at 100% 50%, #e7e6e6, #e9e8e8 71%, rgba(0, 0, 0, 0) 71%)
      no-repeat 2.5em 50%,
    linear-gradient(90deg, #e9e9e9, #cbcbcb) no-repeat 100% 50%,
    radial-gradient(at 0 50%, #d8d8d8, #c3c3c3 71%, rgba(0, 0, 0, 0) 71%)
      no-repeat 0.75em 50%,
    linear-gradient(90deg, #e2e1e1, #cfcfcf) no-repeat 0 50%,
    linear-gradient(#cbcaca, #fafafa, #f8f7f7, #cccccc);
  background-size: 0.825em 100%;
}

input[type="range"]::-webkit-slider-runnable-track:before,
input[type="range"]::-webkit-slider-runnable-track:after,
input[type="range"] /deep/ #track:before,
input[type="range"] /deep/ #track:after {
  position: absolute;
  font:
    0.75em/8em trebuchet ms,
    arial,
    sans-serif;
}

input[type="range"]::-webkit-slider-runnable-track:before,
input[type="range"] /deep/ #track:before {
  top: 50%;
  right: 100%;
  transform: translate(50%, -50%) rotate(0deg) translate(0, 32%);
}

input[type="range"]::-webkit-slider-runnable-track:after,
input[type="range"] /deep/ #track:after {
  left: 50%;
  width: 3em;
  word-spacing: 1em;
}

input[type="range"]:nth-of-type(1)::-webkit-slider-runnable-track:before,
input[type="range"]:nth-of-type(1) /deep/ #track:before {
  content: "320Hz";
}

input[type="range"]:nth-of-type(1)::-webkit-slider-runnable-track:after,
input[type="range"]:nth-of-type(1) /deep/ #track:after {
  bottom: 100%;
  transform: translate(-50%, 50%) rotate(0deg) translate(-4.375em);
  text-align: right;
}

input[type="range"]:nth-of-type(2)::-webkit-slider-runnable-track:before,
input[type="range"]:nth-of-type(2) /deep/ #track:before {
  content: "600Hz";
}

input[type="range"]:nth-of-type(3)::-webkit-slider-runnable-track:before,
input[type="range"]:nth-of-type(3) /deep/ #track:before {
  content: "1KHz";
}

input[type="range"]:nth-of-type(4)::-webkit-slider-runnable-track:before,
input[type="range"]:nth-of-type(4) /deep/ #track:before {
  content: "3KHz";
}

input[type="range"]:nth-of-type(5)::-webkit-slider-runnable-track:before,
input[type="range"]:nth-of-type(5) /deep/ #track:before {
  content: "6KHz";
}

input[type="range"]:nth-of-type(6)::-webkit-slider-runnable-track:before,
input[type="range"]:nth-of-type(6) /deep/ #track:before {
  content: "12KHz";
}

input[type="range"]:nth-of-type(6)::-webkit-slider-runnable-track:after,
input[type="range"]:nth-of-type(6) /deep/ #track:after {
  top: 100%;
  transform: translate(-50%, -50%) rotate(0deg) translate(4.375em);
}

input[type="range"]:nth-of-type(1)::-webkit-slider-runnable-track:after,
input[type="range"]:nth-of-type(1) /deep/ #track:after,
input[type="range"]:nth-of-type(6)::-webkit-slider-runnable-track:after,
input[type="range"]:nth-of-type(6) /deep/ #track:after {
  content: "+18 0 -18";
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #b3b2b2;
}

input[type="range"]:focus::-moz-range-track {
  background: #b3b2b2;
}

input[type="range"]:focus::-ms-track {
  background: #b3b2b2;
}

/**BUtton */

.rangBtnplus {
  position: relative;
  width: auto;
  color: #ecf0f1;
  text-decoration: none;
  border-radius: 5px;
  background: #1aa5c0;
  text-align: center;
  padding: 5px 16px 4px;
  transition: all 0.1s;
  box-shadow: 0px 6px 0px #1589a0;
}

.rangBtnminus {
  position: relative;
  width: auto;
  color: #ecf0f1;
  text-decoration: none;
  border-radius: 5px;
  background: #1aa5c0;
  text-align: center;
  padding: 5px 18px 4px;
  transition: all 0.1s;
  box-shadow: 0px 6px 0px #1589a0;
}

.rangBtnDisabled {
  background: #d9d9d9;
  box-shadow: 0px 6px 0px #b0b2b3;
  color: #bbb;
  pointer-events: none;
  cursor: not-allowed;
  user-select: none;
}

.rangBtnplus:hover,
.rangBtnminus:hover {
  -webkit-box-shadow: 0px 5px 0px #1589a0;
  -moz-box-shadow: 0px 5px 0px #1589a0;
  box-shadow: 0px 5px 0px #1589a0;
  position: relative;
  top: 1px;
}

.rangBtnplus:active,
.rangBtnminus:active {
  -webkit-box-shadow: 0px 2px 0px #1589a0;
  -moz-box-shadow: 0px 2px 0px #1589a0;
  box-shadow: 0px 2px 0px #1589a0;
  position: relative;
  top: 4px;
}

.hide-mediaControl::-webkit-media-controls {
  display: none !important;
}

.hide-mediaControl {
  outline: none;
  /* Optional: to remove outline when video is focused */
}

.hover-text {
  position: relative;
  cursor: pointer;
}

.hover-text::after {
  content: attr(data-full-text);
  position: absolute;
  bottom: 125%;
  /* Position the tooltip above the text */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.hover-text:hover::after {
  visibility: visible;
  opacity: 1;
}

.bg-black{
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

[id^="headlessui-combobox-options-"] {
  width: 100%; /* Makes the element full width */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.camera-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.camera-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.camera-modal video {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
}

.camera-modal-actions {
  margin-top: 15px;
}

.camera-modal-actions button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}