.rdp {
  --rdp-cell-size: 40px !important;
  /* Size of the day cells. */
}

.rdp .my-selected:not([disabled]) {
  font-weight: bold;
  color: white;
  background-color: #1aa5c0;
}

.my-selected:hover:not([disabled]) {
  font-weight: bold;
  color: white;
  background-color: #1aa5c0 !important;
}

.rdp-day:hover:not([disabled]) {
  font-weight: bold;
  color: white;
  background-color: #1aa5c0 !important;
}

.rdp-day {
  margin: 2px !important;
}

.rdp-day:not([disabled]) {
  font-weight: bold;
  color: black;

  &::before {
    border-radius: 4px;
    position: absolute;
    background: #1aa5c0;
    content: "";
    height: 5px;
    width: 20px;
    left: 8px;
    bottom: 5px;
  }

  &::after {
    position: absolute;
    border-radius: 2px;
    background: rgb(211, 32, 39);
    content: "";
    height: 0px;
    width: 0px;
    left: 20px;
    bottom: 5px;
  }
}

/* .my-today {
  font-weight: bold;
  font-size: 100%;
  background-color: #55e25e;
} */

.rdp-table {
  margin-top: 40px !important;
  max-width: 100% !important;
  width: 100%;
}

.rdp-month {
  width: 100%;
}

.rdp-months {
  width: 100%;
}

.rdp-head_cell {
  text-align: left !important;
  padding-left: 15px !important;
}

.rdp-caption_label {
  text-transform: capitalize;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.rdp-caption_dropdowns {
  display: flex !important;
  justify-content: space-between !important;
  gap: 10px !important;
}
.rdp-dropdown{
  padding: 10px !important; 
  border: none !important;
}
.rdp-dropdown:focus {
  padding: 10px !important;
  border: none !important;
}

.rdp-caption {
  width: 94%;
}