
.symptomator {

    user-select: none;
    position:relative;
}
.symptomator ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  .symptomator ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  .symptomator ::-webkit-scrollbar-thumb {
    background: #20a8d3;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  .symptomator ::-webkit-scrollbar-thumb:hover {
    background: #1e9dc8;
  }
  .symptomator ::-webkit-scrollbar-thumb:active {
    background: #20a8d3;
  }
  .symptomator ::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  .symptomator ::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }
  .symptomator ::-webkit-scrollbar-track:active {
    background: #ffffff;
  }
  .symptomator ::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  .symptomator .hoverPart:hover{
      fill: rgb(255 152 0 / 64%) !important;
      stroke: #FF9800;
      stroke-width: 3px;
  }
  
  .symptomator .hoverPart.active{
      fill: rgb(255 152 0 / 64%) !important;
      stroke: #FF9800;
      stroke-width: 3px;
      stroke-linejoin: round;
      stroke-dasharray: 1px;
      }
  
      .symptomator .hoverPart{
  
      fill:transparent;
      cursor:pointer;
  }
  
  .symptomator .popoverli:hover {
      background:linear-gradient(180deg, #2799DA 0%, #6CD9D5 100%) !important;
      background-size: 200% !important;
      color:#ffffff;
      font-weight: bold;
  }
  
  .symptomator .popoverli {
      padding: 14px 20px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 4.33px;
      background: #F4F4F4;
      color: #818181;
      cursor: pointer;
      font-size: 14px;
  }
  
  .symptomator .popoverul {
      display: flex;
      min-width: 260px;
      padding: 0px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      overflow-y: auto;
      list-style: none;
  }
  .symptomator .searchInputS{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAFaADAAQAAAABAAAAFQAAAAAIGxIOAAACP0lEQVQ4EaVUyYoCMRCtjruiuIIXFfHkUfEmovjjgisoCB4VFb264Ya4z8wrJk3ayTiHKeglSdXLS9WrGB9fRoo9Hg+azWa0Xq/pdDrxitvtplAoRKlUivD/lxkq6HA4pMlkQk6nk4QQZBgGx2Pf5/NJt9uN4vE45XK5t7gmaLfbpe12Sw6HgwPAGEAwgNvtdnMePuVymce6F4OC4Xw+Z4YSLJPJUCQSYcbYbDweE9YAfr/fKRgMUqFQ0GGSHY44ssfj4SDkrFgsWpz9fj8lEgnq9/u02WwYeLVa8ckA/moCRUEOYTjuK6AakM/n2Rd+SAHI6EygyigKGOPIf1k2m2VfxICtzgRkg0Jg92g0qvOxzIXDYfZFjFSFxeFrIFSZSgm9OqljMFRNKkSdE1LMANztduqa9h8+kiUcpNRUZ4FOwW42m41loy7q/kejEQPhhLrKI0Ykk0m6Xq9cLOhvMBjosHgOWoVmZWHT6bTWV3i9XorFYixoHGWxWFCr1WI9yoj9fk+9Xo+m0ylLCizxIE5nZpvWajWzY5AOsJZFADOkBw/AZE5xwmq1ara23MAExUSn0+FioRlelQAwaBlfCB/r2BSXTKVSIZfLJTHJAorZ5XLJnYLcSWAEBwIBbg6oBZvjKxlfLhe+YNDqsB+gPPv9gjNMZYExZNVut38Al0ol8vl870EB8JsdDgdqNpsW4PP5zIzfMv0NUM4fj0dqNBomMNIEBf0LFOC4O+r1unnTQSn/BgUwcg8dQwm4uD8BdzdnpqiteCsAAAAASUVORK5CYII=');
      border-radius:5px;
      background-position: 10px 12px;
      background-repeat: no-repeat;
      width: -webkit-fill-available;
      font-size: 16px;
      padding: 12px 20px 12px 40px;
      border: 1px solid #ddd;
      margin-bottom: 12px;
      margin-top: 12px;
  }
  
  .symptomator  .searchInputS:focus, .symptomator .searchInputS:focus-visible{
      border: 2px solid #20a8d3 !important;
      user-select: none;
      outline: none;
  }
  
  .symptomator .searchResult{
      top: 80px;
      border: 1px solid #ddd;
      left: 0;
      margin-left: 12px;
      margin-right: 12px;
      padding: 12px;
      border-radius: 5px;
      width: -webkit-fill-available;
      max-height: 369px;
      position: absolute;
      z-index: 19;
      background: #fff
  }
  
  .symptomator .stickyTopBar{
    position: sticky;
    top: -12px;
    width: 100%;
    padding-bottom: 5px;
    background-color: #f1f8f9;
    padding: 5px;
  }
  
  .symptomator .webui-popover-inner .close:after {
      content: "\00D7";
      width: 0.8em;
      height: 0.8em;
      padding: 4px;
      position: relative;
  }
  
  .symptomator .webui-popover-inner .close {
      color: #20a8d3 !important;
      opacity: 1 !important;
  }
  
  .symptomator .webui-popover-inner .close {
      font-family: arial;
      margin: 8px 10px 0 0;
      float: right;
      font-size: 31px;
      font-weight: bold;
      line-height: 16px;
      color: #000000;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.2;
      filter: alpha(opacity = 20);
      text-decoration: none;
  }
  
  .symptomator .webui-popover-title {
      color: #20a8d3 !important;
  }
  
  .symptomator .webui-popover-inner {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      align-items: center;
   
  }
  
  
  .symptomator .noData{
  
      width:100%;
  
      height:100%;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      
  }
  
  .symptomator .activeli {
      background: linear-gradient(180deg, #2799DA 0%, #6CD9D5 100%);
      color: white;
  }
  
  .symptomator .activeli:hover {
      background: linear-gradient(180deg, #2799DA 0%, #6CD9D5 100%) !important;
      background-size: 600%;
      -webkit-animation: anime 16s linear infinite;
      animation: anime 16s linear infinite;
  }
  
  
  
  .symptomator .masked-overflow {
      scrollbar-width: thin;
      scrollbar-color: #1aa5c1 transparent;
  }
  .symptomator .masked-overflow {
      --scrollbar-width: 8px;
      --mask-height: 32px;
      overflow-y: auto;
     
      padding-bottom: var(--mask-height);
      padding-right: 20px;
      --mask-image-content: linear-gradient(to bottom, transparent, black var(--mask-height), black calc(100% - var(--mask-height)), transparent);
      --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
      --mask-image-scrollbar: linear-gradient(black, black);
      --mask-size-scrollbar: var(--scrollbar-width) 100%;
      mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
      mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
      mask-position: 0 0, 100% 0;
      mask-repeat: no-repeat, no-repeat;
  }
  .symptomator .flexArea {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      gap: 10px;
     
      overflow-y: auto;
  }
  
  .symptomator .symptom {
      background: #ffe6e4 !important;
      color: #333 !important;
      border: 1px solid #ffcbc6;
  }
  .symptomator .flexItems {
      background: #20a8d3;
      /* width: 30%; */
      height: 63px;
      border-radius: 9.77px;
      flex: 1 0 50%;
      display: flex;
      justify-content: space-between;
      padding: 5px 12px;
      fill: white;
      color: white;
      align-items: center;
      font-size: 14px;
  }
  
  .symptomator .cursorPointer{
  
      cursor:pointer
  }
  
  .symptomator  .loader{
    position: absolute;
    z-index: 18;
    background-color: #ffffff82;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  
  .symptomator .lottieNoselect{
  
      user-select: none;
      pointer-events: none;
  }
  
  
  .symptomator .hoverBtn{
      width: 300px;
      position: absolute;
      bottom: 22px;
      right: -25px;
      z-index: 18;
      cursor:pointer;
   }
   .symptomator .assistantBtn{
    width: 300px;
    height: 100px;
    font-size: 30px;
    text-align: center;
    line-height: 100px;
    color: rgba(255,255,255,0.9);
    border-radius: 50px;
    background: linear-gradient(-45deg, #3dd8ff, #ff3d77, #338aff, #3cf0c5);
    background-size: 600%;
    -webkit-animation: anime 16s linear infinite;
            animation: anime 16s linear infinite;
  }
  
  
  .symptomator .assistantBtn{
    position: absolute;
    margin-top: -70px;
    z-index: -1;
    filter: blur(30px);
    opacity: 0.8;
  }

  @-webkit-keyframes anime {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes anime {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
 .symptomator .mainContainer{
  
      width: 100vw;
      overflow-x: hidden;
  }
  
  
  .symptomator .motionPopup{
      background: rgb(245 245 245 / 50%);
  
      padding: 2rem;
      border-radius: 5px;
     
      position: absolute;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
      top: 0%;
      left:0;
      z-index: 18;
      overflow: hidden;
  }
  
  .symptomator .forcedAnimation{
  
      
      bottom: -61px;
      right: 0;
      z-index: 1;
      width: 100%;
  
  }
  
  .closeBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 2rem;
      height: 2rem;
      border: none;
      background: rgba(32, 168, 211, .1);
      border-radius: 50%;
      color: rgb(32 168 211);
      cursor: pointer;
      outline: none;
      transition: background .2s;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
  
  .symptomator .autgmentedAssistantP{
      position:absolute;
      bottom:0;
      right:0;
      pointer-events: none;
  }
  
  .symptomator .react-tooltip span{
  
      text-align: center;
      align-items: center;
      display: flex;
  }
  
  
  .symptomator .underLined{
  
     text-decoration: underline ;
  }
  
  .symptomator .noDataImg{
      height: 100px;
      width: 100px;
      border-radius: 100%;

  }
  
  .symptomator .textMuted{
      color: #ababab;
  }
  
  .symptomator .symptomsSelector{
      position: absolute;
      width: -webkit-fill-available;
      left: 0;
      top: 0px;
      padding: 12px;
  }
  
  .symptomator .rotate-icon {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 8%;
    height: -moz-fit-content;
    /* height: auto; */
    cursor: pointer;
    z-index: 9;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px #eee;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .symptomator .other-icon {
    position: absolute;
    right: 1rem;
    top:1rem;
    width: 8%;
    height: -moz-fit-content;
    /* height: auto; */
    cursor: pointer;
    z-index: 9;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px #eee;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .symptomator .rotate-icon:hover {
      background: #eee;
      /* boxShadow for when hovered */
      box-shadow: 0px 3px 27px 0px #20a8d3;
  }
  
  .symptomator .resultsLoader{
  
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  
  }
  
  .symptomator .toolTipContent{
    max-width: 200px;
      font-size: 16px;
      font-weight: 400;
  }
  
  
  .symptomator .cta {
    display: flex;
    padding: 10px 45px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    color: white;
    cursor: pointer;
    background: linear-gradient(-45deg, #20a8d3, #033060, #167592, #20a8d3);
    transition: 1s;
    box-shadow: 6px 6px 0 rgb(166, 166, 166);
    transform: skewX(-9deg);
    border-radius: 5px;
    position: absolute;
      /* right: 0; */
      bottom: 21px;
      width: 100%;
  }
  
  .symptomator .cta:focus {
   outline: none; 
  }
  
  .symptomator .cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #a3e9ff;
  }
  
  .symptomator .cta span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
  }
  
  .symptomator .cta:hover  span:nth-child(2) {
    transition: 0.5s;
    margin-right: 45px;
  }
  
  
  
  /**************SVG****************/
  
  path.one {
    transition: 0.4s;
    transform: translateX(-60%);
  }
  
  path.two {
    transition: 0.5s;
    transform: translateX(-30%);
  }
  
  .symptomator .cta:hover path.three {
    animation: color_anim 1s infinite 0.2s;
  }
  
  .symptomator .cta:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
  }
  
  .symptomator .cta:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
  }
  
  /* SVG animations */
  
  @keyframes color_anim {
    0% {
        fill: white;
    }
    50% {
        fill: #20a8d3;
    }
    100% {
        fill: white;
    }
  }


  .symptomator .blob {
	background: black;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	margin: 5px;
	height: 5px;
	width: 5px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
    position:absolute;
    z-index: 20;
    pointer-events: none;
}

.symptomator .blob.red {
	background: rgba(255, 82, 82, 1);
	box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
	animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

