/* From Uiverse.io by PriyanshuGupta28 */
.checkbox-wrapper input[type="checkbox"] {
    display: none;
}

.checkbox-wrapper .terms-label {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
    margin-left: 10px;
}

.checkbox-wrapper .checkbox-svg {
    width: 30px;
    height: 30px;
}

.checkbox-wrapper .checkbox-box {
    fill: rgba(207, 205, 205, 0.425);
    stroke: #20a8d3;
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
    stroke: #20a8d3;
    stroke-dasharray: 172;
    stroke-dashoffset: 172;
    transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked+.terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked+.terms-label .checkbox-tick {
    stroke-dashoffset: 0;
}