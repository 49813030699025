#progress {
    padding: 0;
    list-style-type: none;
    font-family: arial;
    font-size: 12px;
     clear: both; 
    line-height: 1em;
    margin: 0 -1px;
    text-align: center;
}

#progress li {
    float: left;
    padding: 10px 30px 6px 21px;
    background:#E6F3FF;
    color: #50799E;
    position: relative;
    min-width: 16%;
    margin: 0 2px;
}

#progress li:before {
    content: '';
    border-left: 16px solid #fff;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    
}
#progress li:after {
    content: '';
    border-left: 16px solid  #E6F3FF;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 20;
}

#progress li.active {
    background: #335D84;
    font-size: small;
    color: #E6F3FF;
}


#progress li.active:after {
    border-left-color:#335D84;
}