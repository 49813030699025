.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 350px;
    background-color: rgb(255, 255, 255);
    color: #000000;
    text-align: center;
    border-radius: 6px;
    bottom: 70px;
    right: 20px;
    padding: 5px 0;
    box-shadow: 20px;


    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}