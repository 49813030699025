@keyframes shake {
    0% {
        transform: translateX(-5px);
    }

    25% {
        transform: translateX(5px);
    }

    50% {
        transform: translateX(-5px);
    }

    75% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

.animate-shake {
    animation: shake 0.5s;
}