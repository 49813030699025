.ectComponent {
    font-family: "Helvetica Neue", "Lucida Grande", "Segoe UI", Arial, Helvetica, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333
}

.ectComponent .clearfix:after {
    content: "";
    clear: both;
    display: table
}

.ectComponent .defaultFontColor {
    color: #333
}

.ectComponent .defaultErrorAlert {
    display: block;
    padding: .75rem 1.25rem;
    color: #721c24;
    background-color: #f8d7da !important;
    border-color: #f5c6cb;
    font-size: 85%
}

.ectComponent .syntaxErrorAlert {
    display: block;
    padding: .75rem 1.25rem;
    color: #004085;
    background-color: #cce5ff !important;
    border-color: #b8daff;
    font-size: 85%
}

.ctw-input:not(.apiInitializationCompleted) {
    background-color: #eee;
    pointer-events: none;
    color: #aea79f
}

.ectComponent .overflowBox {
    overflow: visible;
}

.ectComponent .browserContent {
    height: 85vh
}

.ectComponent .browserBodyLeft,
.ectComponent .browserBodyRight {
    overflow: visible;
    height: 80vh
}

.ectComponent .browserCloseiconWrapper {
    float: right
}

.ectComponent ol,
.ectComponent ul {
    margin-top: 0;
    margin-bottom: 0
}

.ectComponent .embeddedBrowser .browser,
.ectComponent .embeddedBrowser .browserBody,
.ectComponent .embeddedBrowser .browserBodyLeft,
.ectComponent .embeddedBrowser .browserBodyRight,
.ectComponent .embeddedBrowser .browserContent {
    height: inherit;
    max-height: inherit
}

.ctw-eb-window .embeddedBrowserSearchbox {
    width: 30%;
    margin-left: 20px;
    height: 30px
}

.ctw-eb-window .popupMode {
    max-width: 60vw
}

.ctw-eb-window .popupModeResults {
    margin-left: 20px
}

.ectComponent .popupMode {
    position: relative
}

.ectComponent .popupMode .popupModeTop {
    margin-top: 4px
}

.ectComponent .popupMode .popupModeTop .entityLoading {
    margin-left: 6px
}

.ectComponent .popupMode .popupModeTop .closeicon {
    float: right;
    margin-right: 6px
}

.ectComponent .popupMode .popupModeResults {
    position: absolute;
    z-index: 100002;
    top: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    min-width: 40%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    height: auto
}

.ectComponent #ctwFlexContainer {
    display: flex;
    flex-direction: row;
    width: 100%
}

.ectComponent .ctwFlexItem25 {
    width: 25%
}

.ectComponent .ctwFlexItem50 {
    width: 50%
}

.ectComponent .ctwFlexItem75 {
    width: 75%
}

.ectComponent .ctwFlexItem100 {
    width: 100%
}

.ectComponent .ctwFlexItemPadding {
   /*  padding-left: 10px;
    padding-right: 10px */
}

.ectComponent .entityHead.currentEntityHighlighted,
.ectComponent .entityHead:not(.keyboardOnlyHighlight):hover {
    background-color: #eee
}

.ectComponent .importantlabel:not(.keyboardOnlyHighlight):hover,
.ectComponent .importantlabelCurrentEntityHighlighted {
    background-color: #cae1ed !important
}

.ectComponent .entityHead {
    display: flex;
    flex-direction: row
}

.ectComponent .entityTheCode {
    flex-basis: 7em;
    cursor: pointer;
    color: #555;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ectComponent .entityTitleAndIcons {
    width: 100%;
    display: flex;
    flex-direction: row
}

.ectComponent .entityTheCodeInDetails {
    cursor: pointer;
    color: #555;
    font-weight: 600;
    margin-right: 10px
}

.ectComponent .entityTitle {
    width: 100%
}

.ectComponent .entityTitlePointer {
    cursor: pointer
}

.ectComponent .entityPv {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff
}

.ectComponent .entityPvPointer {
    cursor: pointer
}

.ectComponent .entityPv .entityPvBadge {
    font-size: 80%;
    white-space: nowrap;
    background-color: #feeebd;
    border: 1px solid #d19405;
    text-align: center;
    border-radius: 2px;
    margin: 0 5px;
    padding: 0 5px;
    vertical-align: 1px;
    cursor: default
}

.ectComponent .entityDetails {
    margin-top: 10px;
    margin-bottom: 13px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #f8f8f8 !important
}

.ectComponent .entityDetails .entityDetailsSelection .entityHead {
    padding-left: 8px;
    padding-right: 8px
}

.ectComponent .entityDetails .showAllEntities .entityHead {
    padding-left: 4px
}

.ectComponent .entityDetailsError {
    margin-bottom: 13px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px
}

.ectComponent .entityDetails .entityDetailsContentAfterHead {
    font-size: 85%;
    margin-top: 4px
}

.ectComponent .entityDetails .entityDetailsContent {
    padding: 4px 8px
}

.ectComponent .entityDetailsCodeAndTitle {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px
}

.ectComponent .entityDetailsCodeAndTitle .entityDetailsTheCode {
    color: #555;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 10px
}

.ectComponent .entityDetailsSelectionHeader {
    display: flex;
    flex-direction: row
}

.ectComponent .entityDetailsSelectionHeader .mitHeader {
    width: 100%;
    display: flex;
    flex-direction: row
}

.ectComponent .entityDetailsSelectionHeader .mitHeader .mitHeaderFlex {
    width: 100%
}

.ectComponent .entityDetailsSelectionHeader .pcHeader {
    white-space: nowrap
}

.ectComponent .entityDetailsSelection {
    background-color: #fff !important;
    border: 1px solid #e8e6e6;
    padding-top: 4px;
    padding-bottom: 4px
}

.ectComponent .entityDetailsSelection .showAllEntities .entityTitleSelection {
    font-size: 85%
}

.ectComponent .entityDetailsSelection .showAllEntities .entityTitleSelection .showlink {
    margin-left: 4px !important
}

.ectComponent .entityTitleSelection {
    width: 100%;
    display: flex;
    flex-direction: row
}

.ectComponent .entityTitleSelectionFlex {
    width: 100%
}

.ectComponent .entityTitlePointerSelection {
    cursor: pointer
}

.ectComponent .entityDetails .entityDetailsProperties {
    margin-top: 8px;
    font-size: 85%
}

.ectComponent .entityDetails .entityDetailsContent .propertyWrapper {
    margin-bottom: 8px
}

.ectComponent .entityDetails .entityDetailsContent .propertyWrapper.lastPropertyWrapper {
    margin-bottom: 4px
}

.ectComponent .entityDetails .entityDetailsContent .propertyHeader {
    font-weight: 700;
    color: #4e4c48
}

.ectComponent .entityDetails .entityDetailsContent .propertyHeaderSelection {
    font-size: 85%;
    font-weight: 700;
    color: #4e4c48
}

.ectComponent .entityDetails .entityDetailsContent .property {
    margin-left: 10px
}

.ectComponent .embeddedBrowserAllIndexTerms .subIndexTerm {
    margin-left: 2.5rem;
    font-size: 85%;
    display: list-item;
    list-style-type: circle
}

.ectComponent .entityLoading {
    font-weight: 700
}

@keyframes blink {
    0% {
        opacity: .2
    }

    20% {
        opacity: 1
    }

    to {
        opacity: .2
    }
}

.ectComponent .entityLoading span {
    margin-left: 2px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both
}

.ectComponent .entityLoading span:nth-child(2) {
    animation-delay: .2s
}

.ectComponent .entityLoading span:nth-child(3) {
    animation-delay: .4s
}

.ectComponent .elementToFadeOut {
    animation: fadeOut 5s linear forwards;
    border-radius: 2px;
    padding-bottom: 1px
}

@keyframes fadeOut {

    0%,
    90% {
        background-color: #fbdaa0
    }

    to {
        background-color: transparent
    }
}

.ectComponent .foundationCodingTool .ulDescendants {
    padding-left: 30px
}

.ectComponent .browser.browserModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #000;
    background-color: rgba(0, 0, 0, .5) !important
}

.ectComponent .browser.browserModal .browserContent {
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff !important;
    margin: 3% 5%;
    padding: 10px
}

.ectComponent .browser .browserBodyTitle {
    font-weight: 700;
    font-size: 1.2em;
    color: #404040;
    margin-bottom: 6px;
    margin-right: 0;
    border-bottom: 1px solid #ccc
}

.ectComponent .browser .browserBody {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto 1fr
}

.ectComponent .embeddedBrowser .browser .browserBody {
    margin-top: 0
}

.ectComponent .embeddedBrowser .browser .browserBody.hierarchyNotAvailable {
    display: inline;
    display: initial
}

.ectComponent .browser .browserBodyLeft {
    width: 31vw;
    border-right: 1px solid #ccc;
    padding-left: 20px;
    padding-right: 20px
}

.ectComponent .browser .browserBodyLeft.hierarchyResizable {
    resize: horizontal;
    min-width: 15vw;
    max-width: 60vw
}

.ectComponent .browser .browserBodyRight {
    padding-left: 20px;
    padding-right: 20px
}

.ectComponent .browser .propertyHeader {
    font-weight: 700;
    margin-bottom: 8px
}

.ectComponent .browser .property {
    margin-left: 10px;
    margin-bottom: 15px
}

.ectComponent .browser .subproperty {
    font-size: 85%;
    font-style: italic;
    margin-left: 8px
}

.ectComponent .browser .parentcode {
    font-weight: 600;
    font-style: normal;
    margin-right: 4px
}

.ectComponent .browser .propertyHeaderCodingNote,
.ectComponent .browser .propertyHeaderDiagnosticCriteria,
.ectComponent .browser .propertyHeaderPostcoordination {
    background-color: #e8e8f0;
    padding: 2px 10px 4px;
    margin-top: 20px
}

.ectComponent .browser .propertyHeaderCodingNoteSub {
    margin-left: 10px
}

.ectComponent .browser .idclass {
    margin-right: 2px;
    vertical-align: bottom
}

.ectComponent .browser .idclass,
.ectComponent .browser .idclass2 {
    color: #606060;
    font-style: italic;
    font-size: .8em;
    white-space: nowrap
}

.ectComponent .browser .idclass2 {
    margin-left: 8px
}

.ectComponent .browser .orangetextbutton {
    font-size: .8em;
    color: #df7030;
    margin-left: 8px;
    text-decoration: none;
    vertical-align: bottom
}

.ectComponent .browser .markdownDefinition li {
    list-style-type: disc
}

.ectComponent .browser .markdownDefinition table td {
    border-bottom: 1px solid #ddd;
    padding: 4px 20px 4px 0
}

.ectComponent .browser .markdownDefinition table th {
    border-bottom: 1px solid #ddd;
    padding: 4px 20px 4px 0;
    text-align: left
}

.ectComponent .browser .markdownDefinition table ul {
    margin-left: 0;
    padding-left: 0
}

.ectComponent .browser .markdownDefinition table li {
    list-style: none
}

.ectComponent .browser .propertyDiagnosticCriteria {
    font-size: 14px
}

.ectComponent .browser .diagnosticCriteria h1 {
    font-weight: 700;
    font-size: 1em;
    color: #b96021;
    margin-bottom: 4px;
    margin-top: 18px;
    padding-bottom: 0
}

.ectComponent .browser .diagnosticCriteria h2 {
    font-weight: 400;
    font-size: 1em;
    color: #b96021;
    margin-bottom: 4px;
    margin-top: 18px;
    padding-bottom: 0
}

.ectComponent .browser .diagnosticCriteria h3 {
    font-weight: 400;
    font-size: .9em;
    color: #b96021;
    margin-bottom: 4px;
    margin-top: 18px;
    padding-bottom: 0
}

.ectComponent .browser .diagnosticCriteria h4 {
    font-weight: 700;
    font-size: .9em;
    color: #333;
    margin-bottom: 4px;
    margin-top: 18px;
    padding-bottom: 0
}

.ectComponent .openicon {
    margin-right: 4px
}

.ectComponent .entityHead:hover>.openicon {
    display: inline
}

.ectComponent .closeicon {
    font-size: 9.8px;
    color: #777;
    cursor: pointer;
    margin-right: 4px;
    vertical-align: text-top;
    font-weight: 700
}

.ectComponent .closeicon:hover {
    color: #333;
    background-color: #eee
}

.ectComponent .entityDetailsCloseiconWrapper {
    position: relative;
    float: right
}

.ectComponent .entityDetailsCloseicon {
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 9.8px;
    color: #777;
    border-radius: 3px;
    padding: 0 3px 1px 2px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #ccc;
    font-weight: 700
}

.ectComponent .entityDetailsCloseicon:hover {
    color: #333;
    background-color: #eee
}

.ectComponent .dimensionicon {
    font-size: smaller;
    color: #777;
    cursor: pointer;
    margin-right: 4px;
    margin-left: 6px;
    vertical-align: top
}

.ectComponent .dimensionicon:hover {
    color: #333;
    background-color: #eee
}

.ectComponent .showlink {
    color: #4169e1 !important;
    cursor: pointer;
    font-size: 85%;
    font-weight: 400;
    margin-left: 6px
}

.ectComponent .showlinkPostcoordination {
    font-size: 85%;
    margin-right: 3px;
    vertical-align: 2px
}

.ectComponent .showlinkNormalSize {
    color: #4169e1 !important;
    cursor: pointer;
    font-size: 100%;
    font-weight: 400;
    margin-left: 6px
}

.ectComponent .showlink:hover,
.ectComponent .showlinkNormalSize:hover {
    color: #4169e1;
    text-decoration: none
}

.ectComponent .showlinkSeeInHierarchy {
    color: #4169e1 !important;
    cursor: pointer;
    font-size: 85%
}

.ectComponent .showlinkSeeInHierarchy:hover {
    text-decoration: none
}

.ectComponent .iconSeeInHierarchy {
    font-size: 120%;
    color: #fff;
    background-color: #4f74e3;
    padding: 0 4px;
    border-radius: 3px;
    margin-right: 2px
}

.ectComponent .showdetails {
    font-size: 85%;
    padding-right: 2px;
    vertical-align: 2px;
    word-break: keep-all;
    white-space: nowrap
}

.ectComponent .unicodeicon {
    color: #4169e1;
    font-size: 8px;
    cursor: pointer
}

.ectComponent .mandatoryPostcoord {
    color: #50799e
}



.ectComponent .circleicon {
    cursor: pointer
}

.ectComponent .circleicon,
.ectComponent .circleiconWithinDetails {
    font-family: "Courier New";
    font-weight: 700;
    font-size: 9.8px;
    color: #424242;
    border-radius: 50%;
    padding: 1px 4px;
    margin-left: 2px;
    vertical-align: 2px
}

.ectComponent .circleiconWithinDetails {
    cursor: default
}

.ectComponent .circleiconWithinDetailsHeaders {
    font-family: "Courier New";
    font-weight: 700;
    font-size: 9.8px !important;
    color: #424242;
    border-radius: 50%;
    padding: 1px 4px;
    margin-left: 6px;
    cursor: default;
    vertical-align: 2px
}

.ectComponent .pacolor {
    background: #eee;
    border: 1px solid #ccc;
    padding-right: 3px !important;
    font-weight: 700
}

.ectComponent .prcolor {
    background: #facdac;
    border: 1px solid #f8b687;
    padding-right: 3px !important;
    font-weight: 700
}

.ectComponent .mlcolor {
    background: #f8ee69;
    border: 1px solid #f0f00f;
    font-weight: 700
}

.ectComponent .plcolor {
    background: #e2d0e2;
    border: 1px solid #cfb0cf;
    font-weight: 700
}

.ectComponent .cncolor {
    background: #ace259;
    border: 1px solid #9ac754;
    padding: 1px 3px 1px 2px !important;
    font-weight: 700
}

.ectComponent .entityDetails .circleiconWithinDetails {
    cursor: pointer
}

.ectComponent .hierarchy ul {
    list-style: none;
    padding-left: 0
}

.ectComponent .hierarchy ul ul {
    padding-left: 18px
}

.ectComponent .hierarchy .topul li {
    margin-bottom: 3px
}

.ectComponent .hierarchy .collapsed,
.hierarchy .expanded {
    cursor: pointer;
    width: 16px;
    display: inline-block;
    font-size: .8em
}

.ectComponent .hierarchy .collapsed {
    vertical-align: top
}

.ectComponent .hierarchy .expanded {
    vertical-align: top;
    position: relative;
    top: 2px
}

.ectComponent .hierarchy .empty {
    width: 16px;
    display: inline-block
}

.ectComponent .hierarchy .codeinh {
    font-weight: 600
}

.ectComponent .hierarchy .nodeWindow .codeinh,
.ectComponent .hierarchy .nodeWindow .titleinh {
    color: #668
}

.ectComponent .hierarchy .nodeAdoptedChild .codeinh,
.ectComponent .hierarchy .nodeAdoptedChild .titleinh {
    color: #999
}

.ectComponent .hierarchy .nodeResidual .codeinh,
.ectComponent .hierarchy .nodeResidual .titleinh {
    color: maroon
}

.ectComponent .hierarchy .nodeAdoptedResidual .codeinh,
.ectComponent .hierarchy .nodeAdoptedResidual .titleinh {
    color: rgba(128, 0, 0, .6)
}

.ectComponent .hierarchy .hideNodeWindow {
    display: none
}

.ectComponent .hierarchy .labelinh {
    cursor: pointer;
    width: calc(100% - 16px);
    display: inline-block
}

.ectComponent .hierarchy .selected {
    background-color: #fef6e8 !important
}

.ectComponent .hierarchy .labelinh:hover {
    background-color: #eee
}

.ectComponent .hierarchy .rsubtitle {
    font-style: italic;
    color: #505050
}

.ectComponent .browserBodyLeft .hierarchy .rtitle {
    font-weight: 600;
    color: #505050
}

.ectComponent .hierarchy .nodeError {
    border-radius: 3px;
    margin-right: 10px
}

.ectComponent .postcoordinatedCodeLeft {
    float: left
}

.ectComponent .postcoordinatedCodeRight {
    float: right
}

.ectComponent .postcoordColored {
    font-style: normal;
    color: #50799e
}

.ectComponent .postcoordAction {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: .9em;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 0;
    border-left: thin solid #b96021;
    background-color: #f8f8f8
}

.ectComponent .postcoordAction .hierarchy {
    max-height: 300px;
    overflow: auto
}

.ectComponent .postcoordActionClickable {
    cursor: pointer
}

.ectComponent .highlightedEntity,
.ectComponent .postcoordActionClickable:hover {
    background-color: #eee
}

.ectComponent .highlightedEntityPv {
    background-color: #eee;
    border-right: 2px solid #eee !important;
    border-bottom: 2px solid #eee !important
}

.ectComponent .highlightedParent {
    position: relative
}

.ectComponent .highlightedEntityArrow {
    left: -15px;
    position: absolute;
    color: #50799e
}

.ectComponent .icdcodewpc .stemcodestyle {
    font-weight: 500
}

.ectComponent .icdcodewpc .simplifiedcode {
    text-decoration: underline;
    display: inline-block;
    animation: shake .82s cubic-bezier(.36, .07, .19, .97) both;
    animation-iteration-count: 1;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0)
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0)
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0)
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0)
    }
}

.ectComponent .icdcodewpc .codedelimamp {
    display: inline-block;
    margin: 0 1px;
    color: #8080e0;
    font-weight: 500;
    font-size: .9em
}

.ectComponent .icdcodewpc .codedelimsl {
    display: inline-block;
    margin: 0 3px;
    color: #df7030;
    font-weight: 700;
    font-size: .9em
}

.ectComponent .icdcodewpc .xchaptercodestyle {
    font-size: .9em;
    color: #666
}

.ectComponent .postcoordSticky {
    position: -webkit-sticky;
    position: sticky;
    top: -10px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 20px;
    z-index: 100001
}

.ectComponent .postcoordForSelection {
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fef6e8 !important;
    padding: 10px
}

.ectComponent .postcoordForSelection .postcoordCode {
    font-size: 110%;
    cursor: pointer
}

.ectComponent .postcoorForSelectionURI {
    margin-top: 6px
}

.ectComponent .postcoorForSelectionURI .postcoordURI {
    color: #606060;
    font-style: italic;
    font-size: .8em;
    white-space: normal;
    cursor: pointer
}

.ectComponent .postcoorForSelectionURI .postcoordURI .codedelimamp,
.ectComponent .postcoorForSelectionURI .postcoordURI .codedelimsl {
    font-style: normal;
    font-size: 1.2em
}

.ectComponent .postcoordForSelection button {
    color: #4169e1;
    background-color: #fff;
    border: 1px solid #7a7a7a;
    border-radius: 3px
}

.ectComponent .postcoordForSelection button:hover {
    background-color: #eee
}

.ectComponent .postcoordForSelection .postcoordForSelectionLeft {
    float: left
}

.ectComponent .postcoordForSelection .postcoordForSelectionRight {
    float: right
}

.ectComponent .propertyPostcoordinationSummary .pccodeset {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.ectComponent .propertyPostcoordinationSummary .liFlex {
    display: flex
}

.ectComponent .propertyPostcoordinationSummary .axis {
    width: 200px;
    color: #505050;
    font-size: .9em;
    font-weight: 400;
    color: #b96021
}

.ectComponent .propertyPostcoordinationSummary .axisWithName {
    margin-top: 10px
}

.ectComponent .propertyPostcoordinationSummary .values {
    flex-grow: 1
}

.ectComponent .propertyPostcoordinationSummary .pccode {
    font-weight: 600;
    color: #444;
    font-size: .9em;
    margin-right: 10px
}

.ectComponent .propertyPostcoordinationSummary .pctitle {
    color: #444;
    font-size: .9em;
    margin-right: 10px
}

.ectComponent .propertyPostcoordinationSummary .pcdelete {
    font-size: .8em;
    cursor: pointer
}

.ectComponent .postcoordSearch .rtitle {
    color: #b96021
}

.ectComponent .postcoordSearch .rsubtitle {
    font-style: italic;
    color: #505050
}

.ectComponent .postcoordSearchInput input {
    margin-right: 10px;
    width: 40%
}

.ectComponent .postcoordSearchInputToResult {
    position: relative
}

.ectComponent .postcoordSearchResult {
    position: absolute;
    z-index: 100000;
    top: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    min-width: 40%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    max-height: 300px;
    overflow-y: auto
}

.ectComponent .postcoordSearchResult .closeSearch {
    float: right;
    font-size: 9.8px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    position: -webkit-sticky;
    position: sticky;
    top: 0
}

.ectComponent .postcoordSearchResult .postcoordSearchResultList {
    float: left
}

.ectComponent .postcoordSearchResult ul {
    list-style: none;
    padding-left: 0
}

.ectComponent .postcoordSearchResult ul ul {
    padding-left: 18px
}

.ectComponent .postcoordSearchResult .postcoordFlex {
    display: flex;
    flex-direction: row;
    width: 100%
}

.ectComponent .postcoordSearchResult .labelinh {
    cursor: pointer;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 4px
}

.ectComponent .postcoordSearchResult .titleinh {
    width: 100%
}

.ectComponent .postcoordSearchResult .postcoordPvs {
    font-size: small;
    background-color: #fef6e8;
    z-index: 1002;
    overflow: auto;
    margin-left: 7em;
    cursor: auto
}

.ectComponent .postcoordSearchResult .clickinh:hover {
    background-color: #eee
}

.ectComponent .postcoordSearchResult .searchError {
    border-radius: 3px;
    margin-left: 10px;
    margin-right: 10px
}

.ectComponent .messageright {
    float: right
}

.ectComponent .messageleft {
    float: left
}

.ectComponent .wordlist ul {
    list-style: none;
}

.ectComponent .wordlist li {
    cursor: pointer;
    width: 140px;
    text-indent: -10px;
    padding-left: 10px
}

.ectComponent .wordlist li:hover {
    background-color: #eee
}

.ectComponent .wordlist .dontChangeResult {
    color: #b0a0a0
}

.ectComponent .browser ul.toplevel,
.ectComponent .entitylist ul.toplevel {
    list-style: none;
    margin-left: -40px
}

.ectComponent .browser em.foundpc,
.ectComponent .entitylist em.foundpc {
    font-weight: 700;
    font-style: normal;
    color: red
}

.ectComponent .browser em.found,
.ectComponent .browser em.found em.wbe,
.ectComponent .entitylist em.found,
.ectComponent .entitylist em.found em.wbe {
    font-weight: 700;
    font-style: normal;
    color: #50799e
}

.ectComponent .browser em.found em.nonwbe,
.ectComponent .entitylist em.found em.nonwbe {
    font-weight: 400;
    font-style: normal;
    color: #50799e
}

.ectComponent .browser em.foundpc em.wbe,
.ectComponent .entitylist em.foundpc em.wbe {
    font-weight: 700;
    font-style: normal;
    color: red
}

.ectComponent .browser em.foundpc em.nonwbe,
.ectComponent .entitylist em.foundpc em.nonwbe {
    font-weight: 400;
    font-style: normal;
    color: red
}

.ectComponent .browser em.wbe,
.ectComponent .entitylist em.wbe {
    font-weight: 700;
    font-style: normal;
    color: #50799e
}

.ectComponent .browser em.nonwbe,
.ectComponent .entitylist em.nonwbe {
    font-weight: 400;
    font-style: normal;
    color: #50799e
}

.ectComponent .searchresults h5 {
    color: #50799e
}

.ectComponent .searchresults h4 {
    color: #50799e;
    margin-bottom: 0;
    font-size: 18px;
    font-family: "Helvetica Neue", "Lucida Grande", "Segoe UI", Arial, Helvetica, Verdana, sans-serif;
    font-weight: 500;
    line-height: 1.1
}

.ectComponent .detailsicon {
    font-size: 8px;
    color: #728ead;
    cursor: pointer
}

.ectComponent .browsericon {
    font-size: smaller;
    color: #50799e;
    cursor: pointer
}

.ectComponent a.browsericon {
    text-decoration: none
}

.ectComponent a.browsericon:visited {
    color: #50799e
}

.ectComponent .pvs {
    font-size: small;
    background-color: #fef6e8;
    z-index: 1002;
    overflow: auto;
    margin-left: 8em
}

.ectComponent .foundationCodingTool .pvs {
    margin-left: 1.4em
}

.ectComponent .destinationentitiesdiv .toplevel li {
    margin-bottom: 3px
}

.ectComponent .toplevel li {
    list-style: none
}

.ectComponent .searchresults .title {
    text-align: left;
    margin-left: 10px
}

.ectComponent .searchresults ul dt.thecode {
    font-weight: 700;
    color: #606060;
    text-align: left;
    cursor: pointer
}

.ectComponent .searchresults ul dt.thecode-4 {
    width: 3em
}

.ectComponent .searchresults ul dt.thecode-5 {
    width: 3.4em
}

.ectComponent .searchresults ul dt.thecode-6 {
    width: 3.9em
}

.ectComponent .searchresults ul dt.thecode-7 {
    width: 4.6em
}

.ectComponent .searchresults ul dt.thecode-8 {
    width: 4.9em
}

.ectComponent .searchresults ul dt.thecode-9 {
    width: 5.2em
}

.ectComponent .searchresults ul dt.thecode-10 {
    width: 5.9em
}

.ectComponent .searchresults ul dt.thecode-11 {
    width: 6.5em
}

.ectComponent .searchresults ul dt.thecode-12 {
    width: 7em
}

.ectComponent .searchresults dl {
    margin-top: 0;
    margin-bottom: 3px
}

.ectComponent .sortoption {
    position: relative;
    float: right;
    font-size: .9em;
    color: #4169e1
}

.ectComponent .listhead {
    margin-bottom: 35px
}

.ectComponent .listhead h4 {
    margin-top: 10px !important
}

.ectComponent .chdistro {
    margin-left: -40px;
    list-style: none
}

.ectComponent .ch01 {
    background-color: #f7c3b5
}

.ectComponent .ch02 {
    background-color: #e5cbf8
}

.ectComponent .ch03 {
    background-color: #beeb9f
}

.ectComponent .ch04 {
    background-color: #79bd8f
}

.ectComponent .ch05 {
    background-color: #d0fcff
}

.ectComponent .ch06 {
    background-color: #ededed
}

.ectComponent .ch07 {
    background-color: #f2e088
}

.ectComponent .ch08 {
    background-color: #95c3c7
}

.ectComponent .ch09 {
    background-color: #ffff9d
}

.ectComponent .ch10 {
    background-color: #c6f4d5
}

.ectComponent .ch11 {
    background-color: #e3d2b4
}

.ectComponent .ch12 {
    background-color: #fff7ea
}

.ectComponent .ch13 {
    background-color: #7da626
}

.ectComponent .ch14 {
    background-color: #bcd952
}

.ectComponent .ch15 {
    background-color: #eefec3
}

.ectComponent .ch16 {
    background-color: #6dbcdb
}

.ectComponent .ch17 {
    background-color: #ff9c47
}

.ectComponent .ch18 {
    background-color: #facdac
}

.ectComponent .ch19 {
    background-color: #f2e088
}

.ectComponent .ch20 {
    background-color: #94e5d5
}

.ectComponent .ch21 {
    background-color: #d1dbbd
}

.ectComponent .ch22 {
    background-color: #91aa9d
}

.ectComponent .ch23 {
    background-color: #bdd4de
}

.ectComponent .ch24 {
    background-color: #f2ebbf
}

.ectComponent .ch25 {
    background-color: #c3cfeb
}

.ectComponent .ch26 {
    background-color: #d7d8dc
}

.ectComponent .ch27 {
    background-color: #d9cb9e
}

.ectComponent .chapter {
    font-size: .9em;
    vertical-align: central
}

.ectComponent .freq {
    font-style: italic;
    font-size: .9em;
    position: absolute;
    right: 8px;
    top: 0
}

.ectComponent .chtitlediv {
    position: relative;
    margin-left: 10px;
    padding-right: 24px;
    padding-left: 20px;
    text-indent: -20px
}

.ectComponent .chaptertitle,
.ectComponent .showallbutton {
    cursor: pointer;
    text-decoration: underline
}

.ectComponent .showallbutton {
    position: absolute;
    top: -20px;
    white-space: nowrap
}

.ectComponent #chapters {
    position: relative
}

.ectComponent .important {
    margin-left: 6px;
    cursor: default
}

.ectComponent .importantlabel {
    background-color: #dbf2fe !important
}

.ectComponent #flexisearchbtn {
    background-color: #03038d;
    color: #eee;
    padding: 1px 8px;
    cursor: pointer;
    margin-right: 2px;
    display: inline-block
}

.ectComponent .flexisearchwarning {
    position: absolute;
    top: 30px;
    right: 6px;
    color: #03038d;
    font-weight: 300
}

.ectComponent .flexi {
    color: #03038d;
    font-weight: 300
}

.ectComponent .clipboard,
.ectComponent .pcicon {
    color: #777;
    font-weight: 300
}

.ectComponent .pcicon {
    font-size: .8em
}

.ectComponent .entityInList .notleaf,
.ectComponent .postcoordSearchResultList .notleaf {
    background-color: #ececec;
    color: #555;
    font-weight: 400;
    padding-left: 2px;
    font-size: 1.1em;
    padding-right: 2px
}

.ectComponent .chopped {
    color: #50799e
}

.ectComponent select {
    color: #4169e1
}

.ectComponent .filterSwitch {
    position: relative;
    display: inline-block;
    top: 2px;
    right: 1px;
    width: 43px;
    height: 17px
}

.ectComponent .filterSwitch input {
    opacity: 0;
    width: 0;
    height: 0
}

.ectComponent .filterSwitch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    transition: .4s
}

.ectComponent .filterSwitch .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: #fff;
    transition: .4s
}

.ectComponent .filterSwitch input:checked+.slider {
    background-color: #9acbb1
}

.ectComponent .filterSwitch input:focus+.slider {
    box-shadow: 0 0 1px #9acbb1
}

.ectComponent .filterSwitch input:checked+.slider:before {
    transform: translateX(26px)
}

.ectComponent .filterSwitch .slider.round {
    border-radius: 34px
}

.ectComponent .filterSwitch .slider.round:before {
    border-radius: 50%
}

.ectComponent .filterSwitchLabel {
    margin-right: 6px;
    margin-left: 6px;
    color: #777
}

.ectComponent .filterSwitchStar {
    color: #50799e;
    cursor: help
}

.ectComponent #statusFlexContainer {
    display: flex;
    flex-direction: row;
    width: 100%
}

.ectComponent .statusFlexWords {
    text-align: left;
    color: #777
}

.ectComponent .statusFlexInfo {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ectComponent .statusFlexFilter {
    text-align: right
}

.ectComponent .statusError {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.ectComponent .textNotFound {
    color: #777
}

.ectComponent .tooltipWrapper {
    text-align: left;
    text-align: initial;
    position: relative;
    display: inline-block;
    z-index: 100003
}

.ectComponent .tooltipContent {
    position: absolute;
    width: 25vw;
    max-width: 80vw;
    max-height: 600px;
    overflow: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0/20%), 0 6px 20px 0 rgb(0 0 0/19%);
    color: #777;
    font-size: 14px;
    font-weight: 400
}

.ectComponent .tooltipCloseiconWrapper {
    float: right
}

.ectComponent .tooltipLinkKeyboard {
    color: #777;
    vertical-align: -1px;
    cursor: help;
    margin-right: 25px
}

.ectComponent .tooltipLinkKeyboard:hover,
.ectComponent .tooltipLinkKeyboardOpen {
    background-color: #eee
}

.ectComponent .tooltipContentKeyboard {
    top: 5px;
    right: 1vw
}

.ectComponent .tooltipPostcoordination {
    font-family: "Courier New";
    font-weight: 700;
    font-size: 9.8px;
    border-radius: 50%;
    padding: 1px 4px;
    cursor: help;
    vertical-align: 2px;
    color: #4169e1;
    background-color: #fff;
    border: 1px solid #7a7a7a;
    margin-left: 4px;
    margin-right: 6px
}

.ectComponent .tooltipPostcoordination:hover {
    background-color: #eee;
    border-color: #4169e1
}

.ectComponent .tooltipOpenEndedPostcoordination {
    vertical-align: 3px !important
}

.ectComponent .tooltipContentPostcoordination {
    list-style-type: none
}

.ectComponent .tooltipContentPostcoordination ul {
    list-style-type: none;
    padding: 0;
    margin: 0
}

.ectComponent .statusAdvancedsearch {
    white-space: nowrap;
    color: #d19405 !important;
    cursor: pointer;
    font-size: 100%;
    font-weight: 400;
    margin-right: .5em
}

.ectComponent .statusAdvancedsearch .unicodeicon {
    color: #d19405 !important;
    vertical-align: 0
}

.ectComponent .advancedsearchdiv {
    display: flex;
    background-color: #feeebd;
    border: 1px solid #d19405;
    border-radius: 3px;
    margin-left: .5em;
    margin-right: .5em;
    margin-bottom: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: .5em
}

.ectComponent .advancedsearchdiv .advancedsearchIndexTerms {
    display: inline-block;
    margin-right: 1em
}

.ectComponent .advancedsearchdiv .advancedsearchInput {
    display: inline-block;
    margin-right: .5em
}

.ectComponent .advancedsearchdiv .advancedsearchIndexTerms input,
.ectComponent .advancedsearchdiv .advancedsearchIndexTerms label,
.ectComponent .advancedsearchdiv .advancedsearchInput input,
.ectComponent .advancedsearchdiv .advancedsearchInput label {
    vertical-align: 1px;
    cursor: pointer
}

.ectComponent .browser,
.ectComponent .entityTheCode,
.ectComponent .postcoordCode,
.ectComponent .statusFlexInfo .clipboard {
    direction: ltr
}

.rtl .ectComponent .advancedsearchdiv,
.rtl .ectComponent .browserBodyLeftContent,
.rtl .ectComponent .browserBodyRightContent,
.rtl .ectComponent .chapterdistributionandfilterdiv,
.rtl .ectComponent .destinationentitiesdiv,
.rtl .ectComponent .filterWrapper,
.rtl .ectComponent .statusFlexInfo,
.rtl .ectComponent .statusFlexWords,
.rtl .ectComponent .wordlistdiv {
    direction: rtl
}

.rtl .ectComponent .browserCloseiconWrapper {
    float: left
}

.rtl .ectComponent .browserCloseiconWrapper .closeicon {
    margin-right: 0;
    margin-left: 4px
}

.rtl .ectComponent .chapterdistributionandfilterdiv .chdistro,
.rtl .ectComponent .entitylist ul.toplevel,
.rtl .ectComponent .wordlist ul {
    padding-right: 0;
    padding-left: 40px
}

.rtl .ectComponent .entityInList .entityHead .entityTheCode,
.rtl .ectComponent .postcoordSearchResult .entityTheCode {
    padding-left: 6px
}

.rtl .ectComponent .sortoption {
    float: left
}

.rtl .ectComponent .browser .hierarchy .topul {
    padding-right: 0
}

.rtl .ectComponent .browser .postcoordAction {
    padding-left: 0;
    padding-right: 10px;
    border-left: initial;
    border-right: thin solid #b96021
}

.rtl .ectComponent .wordlist li {
    padding-right: 10px
}

.rtl .ectComponent .chapterdistributionandfilterdiv .freq {
    right: auto;
    left: 8px
}

.rtl .ectComponent .entityDetailsCodeAndTitle .entityDetailsTheCode,
.rtl .ectComponent .entityTheCodeInDetails {
    margin-right: 0;
    margin-left: 10px
}

.rtl .ectComponent .entityDetailsCloseiconWrapper {
    float: left
}

.rtl .ectComponent .entityDetailsCloseicon {
    right: -12px
}

.rtl .ectComponent .entityDetailsSelectionHeader .mitHeader {
    padding-left: 0;
    padding-right: 4px
}

.rtl .ectComponent .entityDetailsSelectionHeader .pcHeader {
    text-align: left
}

.rtl .ectComponent .pvs {
    margin-left: 0;
    margin-right: 8em
}

.rtl .ectComponent .entityPv {
    border-right: 0 solid #fff;
    border-left: 2px solid #fff
}

.rtl .ectComponent .highlightedEntityPv {
    border-right: 0 solid #eee !important;
    border-left: 2px solid #eee !important
}

.rtl .ectComponent .circleiconWithinDetailsHeaders {
    margin-left: 0;
    margin-right: 6px
}

.rtl .ectComponent .iconSeeInHierarchy {
    margin-right: 0;
    margin-left: 2px
}

.rtl .ectComponent .postcoordSearchInput input,
.rtl .ectComponent .postcoordSearchResult {
    margin-right: 0;
    margin-left: 10px
}

.rtl .ectComponent .postcoordSearchResult .closeSearch {
    float: left
}

.rtl .ectComponent .postcoordSearchResult .postcoordSearchResultList {
    float: right
}

.rtl .ectComponent .postcoordSearchResult .postcoordPvs {
    margin-left: 0;
    margin-right: 7em
}

.rtl .ectComponent .showlink,
.rtl .ectComponent .showlinkNormalSize {
    margin-left: 0;
    margin-right: 6px
}

.rtl .ectComponent .entityDetailsSelection .showAllEntities .entityTitleSelection .showlink {
    margin-left: 0 !important;
    margin-right: 8px !important
}

.rtl .ectComponent .browser .property {
    margin-left: 0;
    margin-right: 10px
}

.rtl .ectComponent .browser .subproperty {
    margin-left: 0;
    margin-right: 8px
}

.rtl .ectComponent .browser .parentcode {
    margin-right: 0;
    margin-left: 4px
}

.ctw-eb-window.rtl .embeddedBrowserSearch {
    text-align: right
}

.ctw-eb-window.rtl .embeddedBrowserSearchbox {
    margin-left: 0;
    margin-right: 20px;
    direction: rtl
}

.ctw-eb-window.rtl .popupMode .popupModeTop .entityLoading,
.rtl .popupMode .popupModeTop .entityLoading {
    float: right;
    margin-left: inherit;
    margin-right: 6px
}

.ctw-eb-window.rtl .popupMode .popupModeTop .closeicon,
.rtl .popupMode .popupModeTop .closeicon {
    float: left;
    margin-right: 0;
    margin-left: 6px
}

.ctw-eb-window.rtl .popupMode {
    max-width: none
}

.ctw-eb-window.rtl .popupMode .popupModeResults {
    right: 20px
}

.rtl .ectComponent .advancedsearchdiv .advancedsearchIndexTerms {
    margin-right: 0;
    margin-left: 2em
}

.rtl .ectComponent .advancedsearchdiv .advancedsearchInput {
    margin-right: 0;
    margin-left: .5em
}

.rtl .ectComponent .browser .idclass2 {
    margin-left: 0;
    margin-right: 8px
}

.rtl .ectComponent .embeddedBrowserAllIndexTerms .subIndexTerm {
    margin-left: 0;
    margin-right: 2.5rem
}

.rtl .ectComponent .propertyPostcoordinationSummary .pcdelete {
    margin-right: 10px
}

.rtl .ectComponent .tooltipCloseiconWrapper {
    float: left
}

.rtl .ectComponent .tooltipWrapper {
    direction: rtl
}

.rtl .ectComponent .tooltipPostcoordination {
    margin-left: 6px;
    margin-right: 4px
}

.rtl .ectComponent .tooltipContentPostcoordination {
    left: auto;
    right: -1vw
}

.vueSimplifie{
   
    position: absolute;
    top: 0;
    background: white;
    z-index: 3;
    left: 0;
    padding: 12px;

}