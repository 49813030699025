div label input {
  margin-right: 50px;
}

.cat {
  margin: 1px;
  background-color: #e0effb;
  border-radius: 4px;
  border: 1px solid #fff;
  overflow: hidden;
  float: left;
  width: 135%;
}

.cat label {
  float: left;
  line-height: 3em;
  width: 8em;
  height: 3em;
}

.cat label span {
  text-align: center;
  padding: 3px 0;
  display: block;
  color: "#0F2552";
  width: 140%;
}

.cat label input {
  position: absolute;
  display: none;
  color: #fff !important;
}
/* selects all of the text within the input element and changes the color of the text */
.cat label input + span {
  color: "#0F2552";
}

/* This will declare how a selected input will look giving generic properties */
.cat input:checked + span {
  color: #20a8d3;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}

/*
This following statements selects each category individually that contains an input element that is a checkbox and is checked (or selected) and chabges the background color of the span element.
*/

.action input:checked + span {
  background-color: #ffff;
}
